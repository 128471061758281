@import './mixins';

* {
  line-height: 1;
}

h2,
h3,
h4 {
  margin-top: 0.2em;
  margin-bottom: 0.8em;
}

h1 {
  font-size: 5.4rem;
  color: $color-slate-dark-3;

  @include theme(dark) {
    color: $color-white;
  }

  @include respond(phone) {
    font-size: 4.2rem;
  }
}

h2 {
  font-size: 4.6rem;
  color: $color-slate-light-4;

  @include respond(phone) {
    font-size: 3.8rem;
  }
}

h3 {
  font-size: 4.2rem;
  color: $color-slate-dark-3;

  @include theme(dark) {
    color: $color-white;
  }

  @include respond(phone) {
    font-size: 3.4rem;
  }
}

h4 {
  font-size: 3.6rem;
  color: $color-slate-dark-3;

  @include theme(dark) {
    color: $color-slate-light-2;
  }

  @include respond(phone) {
    font-size: 3rem;
  }
}

h5,
h6 {
  font-family: $font-mono;
  color: $color-primary-dark;

  @include theme(dark) {
    color: $color-primary-light;
  }
}

h5 {
  font-size: 1.6rem;
  font-weight: 400;
}

h6 {
  font-size: 1.2rem;
}

p,
div,
body,
li {
  font-size: 1.7rem;
  line-height: 1.6em;
  color: $color-slate-dark-4;

  @include theme(dark) {
    color: $color-slate-light-2;
  }
}

.sub_heading {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: $font-mono;
  color: $color-primary-dark;

  @include theme(dark) {
    color: $color-primary-light;
  }
}
