@import '../../styles/variables';
@import '../../styles/mixins';

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: $font-mono;
  font-size: 1.4rem;

  padding: 1.2rem 3rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.3rem $color-primary-dark;
  color: $color-slate-dark-2;
  background-size: 220%;
  border: 2px solid $color-primary-dark;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-primary-dark 50%);
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  @include theme(dark) {
    border-color: $color-primary-main;
    color: $color-white;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-primary-main 50%);
  }

  & > span {
    margin-right: 1rem;
  }

  &:hover,
  &:active {
    background-position: 100%;
    color: $color-white;
    box-shadow: 0 0 1rem $color-primary-dark;
  }
}

@keyframes load {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

.button[disabled] {
  pointer-events: none;
  background-position: 100%;
  box-shadow: 0 0 0rem $color-primary-dark;

  color: inherit;

  background-image: linear-gradient(
    120deg,
    transparent 33%,
    $color-primary-dark 33%,
    $color-primary-dark 66%,
    transparent 66%
  );

  @include theme(dark) {
    background-image: linear-gradient(
      120deg,
      transparent 33%,
      $color-primary-main 33%,
      $color-primary-main 66%,
      transparent 66%
    );
  }

  animation-name: load;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
