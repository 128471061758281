@import '../../styles/animations';
@import '../../styles/mixins';
@import '../../styles/variables';

.hero {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow-x: hidden;

  .container {
    @include marginate;
    position: relative;
  }

  .intro {
    // animation-name: fadeAppear;
    // animation-duration: 1s;
    // animation-delay: 3.8s;
  }

  .hero__name {
    line-height: 1.2em;

    // animation-name: fadeAppear;
    // animation-duration: 1.3s;
    // animation-delay: 3.8s;

    .emphasize {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.2rem;
        left: 0%;
        width: 98%;
        height: 0.5rem;
        border-radius: 1rem;
        background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
        box-shadow: 0 0 1rem $color-primary-dark;

        // animation-name: growHorizontal;
        // animation-duration: 1.3s;
        // animation-delay: 4.3s;
        // animation-timing-function: cubic-bezier(0.9, 0, 0.5, 0.9);
      }
    }
  }

  .info {
    font-size: 2.6rem;
    max-width: 80rem;
    line-height: 1.15em;

    // animation-name: fadeAppear;
    // animation-duration: 1.8s;
    // animation-delay: 3.8s;
  }

  .waterMark {
    position: absolute;
    font-family: $font-mono;
    opacity: 0.1;
    z-index: -10;
    bottom: 100%;
    left: 100%;
    font-size: 18rem;
    display: inline-block;
    transform: rotate(90deg) translate(100%, 100%);
    @include respond(huge) {
      font-size: 25rem;
    }
  }

  .quickContact {
    @include marginate;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    font-family: $font-mono;
    font-size: 1.4rem;

    // animation-name: fadeAppear;
    // animation-duration: 2s;
    // animation-delay: 3.8s;

    @include respond(phone) {
      font-size: 1.4rem;

      a {
        padding: 1rem 0;
      }
    }
  }
}
