@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/typography';

.contact {
  @include sectionStyles(even);

  .container {
    text-align: center;
  }

  .heading,
  .sub_heading {
    text-align: center;
  }

  .heading {
    margin-bottom: $margin-medium;
  }

  &__form {
    width: 50rem;
    margin: 0 auto;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
}

.input {
  position: relative;
  margin-bottom: $margin-medium;

  &__label {
    position: absolute;
    top: 0;
    left: 1.2rem;
    display: inline-flex;
    text-transform: capitalize;
    height: 4.5rem;
    align-items: center;
    transition: all 0.2s;
  }

  &__field:not(:placeholder-shown) + &__label {
    top: -3.5rem;
    left: 0;
    font-size: 1.8rem;
  }

  &__field {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 4.5rem;
    padding: 0.5rem 1rem;
    font-family: $font-default;
    color: inherit;
    font-size: 2rem;
    background-color: transparent;
    border: 2px solid $color-primary-dark;
    border-radius: $radius-small;
    transition: all 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown):invalid {
      background-color: rgba($color-tomato, 0.2);
      border-color: $color-tomato;
      box-shadow: 0 0 0.3rem $color-tomato !important;
    }

    &:hover {
      box-shadow: 0 0 0.3rem $color-primary-dark;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: 0 0 0.6rem $color-primary-dark;
      background-color: $color-slate-light-1;

      @include theme(dark) {
        background-color: $color-slate-dark-5;
      }
    }

    @include theme(dark) {
      border: 2px solid $color-primary-main;
    }
  }
}
