@import '../../styles/mixins';
@import '../../styles/variables';

.OtherProjects {
  @include sectionStyles(even);

  .heading {
    margin-bottom: $margin-medium;
  }

  &__list {
    list-style: none;
    background-color: $color-white;
    border-radius: $radius-small;
    box-shadow: $shadow-subtle;
    overflow: hidden;

    @include theme(dark) {
      background-color: $color-slate-dark-5;
    }
  }
}
