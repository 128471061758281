@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/typography';

.statistics {
  @include sectionStyles(odd);

  &__container {
    display: grid;
    grid-gap: 4rem;
    margin-bottom: $margin-medium;
  }

  .data {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-gap: 2rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }
  }

  .contributions {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .stats {
    display: grid;
    grid-gap: 2rem;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
  }

  .cta {
    text-align: center;
  }
}
