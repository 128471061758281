@import '../../../styles/variables';
@import '../../../styles/mixins';

.skills {
  .subheading {
    @include respond(phone) {
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  .skill_rack {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    @include respond(phone) {
      justify-content: space-around;
    }

    & > span {
      display: block;
      filter: brightness(90%);
      cursor: grab;

      margin: 0 2rem 2rem 0;

      transition: all 0.2s;

      @include respond(phone) {
        margin: 0 2rem 3rem 2rem;
      }

      @include respond(touchscreen) {
        filter: none;
      }

      // transition: filter .2s;

      &:hover {
        filter: brightness(110%) drop-shadow(0 0 0.5rem $color-primary-dark);
      }
    }
  }
}
