@import '../../styles/mixins';
@import '../../styles/variables';

.workExperiences {
  @include sectionStyles(odd);

  .heading {
    text-align: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
