@import '../../styles/variables';
@import '../../styles/mixins';

.techItem {
  font-family: $font-mono;
  font-size: 1.2rem;
  color: $color-slate-light-4;

  @include theme(dark) {
    color: $color-slate-light-3;
  }
}
