@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/typography';
@import '../../styles/animations';

.experience {
  display: flex;
  gap: 4.5rem;
  @include respond(phone-large) {
    gap: 3rem;
  }

  &:not(:last-child) {
    .marginBottom {
      margin-bottom: 3rem;
    }
  }

  &__timeline {
    flex-shrink: 0;
    text-align: center;
    width: 18rem;

    @include respond(phone-large) {
      visibility: hidden;
      width: 0;
      margin-left: -1.5rem;
    }
  }

  &__logoSection {
    flex-shrink: 0;
    background: $color-slate-light-4;
    width: 2px;
    position: relative;
    @include theme(dark) {
      background: $color-white;
    }
    @include respond(phone-large) {
      width: 1px;
    }
  }

  &__logo {
    flex-shrink: 0;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: -30px;
    box-shadow: 0 0 0.75rem $color-slate-light-4;

    @include theme(dark) {
      box-shadow: 0 0 0 $color-slate-light-4;
    }

    @include respond(phone-large) {
      width: 3.5rem;
      height: 3.5rem;
      left: -16px;
    }
  }

  .description {
    flex-basis: 100%;
  }

  &__title {
    line-height: 1em;
    margin: 1rem 0;

    & > a {
      text-decoration: none;
      color: currentColor;
    }
  }

  &__role {
    font-size: 1.8rem;
    font-weight: 500;
    font-family: $font-mono;
    color: $color-primary-dark;

    @include theme(dark) {
      color: $color-primary-light;
    }
  }

  &__dateLocation {
    visibility: hidden;
    width: 0;
    height: 0;
    @include respond(phone-large) {
      width: fit-content;
      height: fit-content;
      visibility: visible;
      font-size: 1.5rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex-grow: 1;

    @include respond(phone-large) {
      padding: 0;
    }
  }

  &__description {
    margin-bottom: 1rem;
  }

  &__techStack {
    margin-top: auto;
    display: flex;
    row-gap: 1.5rem;
    column-gap: 3rem;
    flex-wrap: wrap;
  }
}

.techItem {
  font-family: $font-mono;
  font-size: 1.2rem;
  color: $color-slate-light-4;

  @include theme(dark) {
    color: $color-slate-light-3;
  }
}

.responsibilities {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;

  @include respond(phone) {
    flex-direction: column;
  }

  .responsibility {
    display: flex;
    align-items: flex-start;
    list-style: none;
    transition: all 0.3s;
    cursor: default;

    &:hover {
      transform: translateX(0.5rem);
      color: $color-primary-main;

      @include theme(dark) {
        color: lighten($color-primary-light, 2%);
      }
    }

    &::before {
      content: '\e907';
      display: inline-block;
      margin-right: 0.5rem;
      font-family: 'Icomoon' !important;
      color: $color-primary-dark;

      @include theme(dark) {
        color: $color-primary-light;
      }
    }
  }
}

