@import '../../styles/mixins';
@import '../../styles/variables';

.footer {
  text-align: center;
  background-color: $color-slate-light-2;
  padding: 2rem;

  @include theme(dark) {
    background-color: inherit;
  }

  & > div {
    margin: 1rem 0;
  }

  .colorize {
    font-family: $font-mono;
    font-size: 1.6rem;
    color: $color-primary-dark;

    @include theme(dark) {
      color: $color-primary-light;
    }
  }
}
