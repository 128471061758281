@import './variables';

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-icon !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-code:before {
  content: '\e900';
}

.icon-user-outline:before {
  content: '\e901';
}
.icon-arrow-right:before {
  content: '\e902';
}
.icon-check:before {
  content: '\e906';
}
.icon-chevron-right:before {
  content: '\e907';
}
.icon-download:before {
  content: '\e903';
}
.icon-external-link:before {
  content: '\e904';
}
.icon-facebook:before {
  content: '\e905';
}
.icon-github:before {
  content: '\e908';
}
.icon-globe:before {
  content: '\e90b';
}
.icon-instagram:before {
  content: '\e90e';
}
.icon-link:before {
  content: '\e909';
}
.icon-link-2:before {
  content: '\e90f';
}
.icon-linkedin:before {
  content: '\e90a';
}
.icon-mail:before {
  content: '\e910';
}
.icon-send:before {
  content: '\e90c';
}
.icon-slack:before {
  content: '\e90d';
}
.icon-star:before {
  content: '\e911';
}
.icon-twitter:before {
  content: '\e912';
}
