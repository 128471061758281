@import '../../styles/mixins';
@import '../../styles/variables';

.about {
  position: relative;
  @include sectionStyles(even);

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-bottom: 2rem;

    .description {
      flex-basis: 60rem;
      flex-grow: 1;
    }

    .profile {
      text-align: center;
      max-width: 250px;
    }

    .image {
      width: 250px;
      height: 250px;
      align-self: center;
      object-fit: contain;
      border: 2px solid transparent;
      box-shadow: 0 0 1rem transparent;
      transition: all 0.4s;
      background-color: $color-white;
      border-radius: 50%;

      @include respond(phone-large) {
        width: 200px;
        height: 200px;
      }
      border: 2px solid $color-primary-dark;
      box-shadow: 0 0 1rem $color-primary-dark;
    }
  }

  .socialLinks {
    @include respond(phone) {
      text-align: center;
    }
  }
}

