@keyframes projectOverview {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeInTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeSnackBar {
  0% {
    transform: translate(-50%, 5rem);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes flyInRight {
  0% {
    transform: translateX(20rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes flyInLeft {
  0% {
    transform: translateX(-20rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes load {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes fadeAppear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInHero {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes growHorizontal {
  from {
    width: 0;
  }
  to {
    width: 98%; //fix
  }
}
