@import '../../styles/variables';
@import '../../styles/mixins';

.toggle {
  display: block;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: transform 0.2s ease-in-out;
  padding: 0;
  background: transparent;
  border: none;

  > svg {
    fill: $color-slate-dark-1;
    @include theme(dark) {
      fill: $color-white;
    }
  }
}

